import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Create, Delete, DeleteOutline, EditOutlined, Visibility, AttachFile, VisibilityOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';

import { BLACK, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React from 'react';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { CashBalance } from 'typings/CashBalanceModel';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  cellStart: {
    width: '3%'
  },
  cellMiddle: {
    width: '14%'
  },
  cellMiddleDesc: {
    width: '15%'
  },
  cellEnd: {
    width: '15%'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  },
  gap: {
    marginBottom: theme.spacing(2)
  }
}));
interface Props {
  // journalTransactions: transactionJurnal;
  cashBalance: CashBalance;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
}
const TransactionBody = ({ cashBalance, handleAction }: Props) => {
  const classes = useStyles();
  return (
    <>
      <TableRowCustom key={cashBalance.id}>
        <TableCellStart className={clsx(classes.cellMiddle)}>{cashBalance.financialAccountName}</TableCellStart>
        <TableCellMiddle className={clsx(classes.cellMiddle)} align='center'>
          <NumberFormat value={cashBalance.total} prefix={'Rp '} thousandSeparator={true} displayType='text' />
        </TableCellMiddle>
        <TableCellMiddle className={classes.cellMiddle} align='center'>
          {format(new Date(cashBalance.date), 'dd MMM yyyy')}
        </TableCellMiddle>
        <TableCellMiddle className={classes.cellMiddle} align='center'>
          {format(new Date(cashBalance.createdAt), 'dd MMM yyyy')}
        </TableCellMiddle>
        <TableCellEnd className={classes.cellEnd} align='right'>
          <>
            <Tooltip title='Perbaharui Biaya'>
              <IconButton size='small' onClick={() => handleAction(true, cashBalance.id, 'update')}>
                <EditOutlined fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Hapus Biaya'>
              <IconButton size='small' onClick={() => handleAction(true, cashBalance.id, 'delete')}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        </TableCellEnd>
      </TableRowCustom>
    </>
  );
};

export default TransactionBody;
