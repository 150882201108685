import React from 'react';
import { TypeOperasionalResource } from 'pages/OperationalPage';
import { Divider, Grid, makeStyles, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components';
import { Skeleton } from '@material-ui/lab';
import OperationalBody from './OperationalBody';

interface Props {
  operasionalResource: TypeOperasionalResource;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
  handleOpenCarousel: (isOpen: boolean, id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTableSecond: {
    width: '5%'
  },
  container: {
    minHeight: '30vh',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)'
  }
}));
const OperationalTable = ({ operasionalResource, handleAction, handleOpenCarousel }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            headers={[
              { id: 'id', label: 'id', align: 'center' },
              { id: 'name', label: 'Nama', align: 'center' },
              { id: 'accountCategoriesName', label: 'Akun beban', align: 'center' },
              { id: 'financialAccountName', label: 'Akun keuangan', align: 'center' },
              { id: 'price', label: 'Nominal', align: 'center' },
              { id: 'image', label: 'Lampiran', align: 'center' },
              { id: 'akun', label: 'vendor', align: 'center' },
              { id: 'createdAt', label: 'Tanggal', align: 'center' },
              { id: 'action', label: 'Aksi', align: 'right' }
            ]}
          />
        </TableHead>

        <TableBody>
          {operasionalResource.isLoading ? (
            <TableRow>
              <TableCell colSpan={8}>
                <Grid xs={12} container>
                  {[...new Array(5)].map((val, key) => (
                    <Grid xs={12}>
                      <Skeleton variant='text' animation='wave' height={60} key={key} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          ) : operasionalResource.data.length > 0 ? (
            operasionalResource.data.map((operasionalResource, key) => (
              <OperationalBody operasionalResource={operasionalResource} handleAction={handleAction} handleOpenCarousel={handleOpenCarousel} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align='center'>
                <Grid xs={12} className={classes.container} container alignItems='center' justify='center'>
                  <Typography variant='h6'>Data Tidak Tersedia.</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default OperationalTable;
