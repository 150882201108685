import React, { memo, useEffect, useState } from 'react';
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  IconButton,
  TextField,
  makeStyles,
  Button,
  CircularProgress,
  MenuItem,
  InputAdornment,
  Tooltip
} from '@material-ui/core';
import { JOURNAL_TRANSACTIONS_BASE_URL, PARTNER_BASE_URL } from 'constants/url';
import { Close } from '@material-ui/icons';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyTransactionJurnal, dummyPartner } from 'utils/dummy';
import axios from 'axios';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',

    maxWidth: 500,
    justifyContent: 'center'
  },
  tab: {
    minWidth: 'auto', // Mengurangi lebar minimum Tab
    padding: '10px', // Mengurangi padding Tab
    margin: '0 4px' // Mengurangi jarak antar Tab
  }
});

interface Props {
  financialAccounts: AccountCategoryModel[];
  subAccountCategories: AccountCategoryModel[];
  journalTransactionsId: transactionJurnal | null;
  open: boolean;
  type: string;
  handleCloseUpdateOtherIncome: () => void;
  reFetchData: () => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const UpdateOtherIncome = ({
  handleSnackBar,
  financialAccounts,
  type,
  journalTransactionsId,
  subAccountCategories,
  open,
  handleCloseUpdateOtherIncome,
  reFetchData
}: Props) => {
  const classes = useStyles();
  const [transactionJurnal, setTransactionJurnal] = useState<transactionJurnal>(dummyTransactionJurnal);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);
  const disabled =
    (Number(transactionJurnal.debit) === 0 && Number(transactionJurnal.credit) === 0) ||
    transactionJurnal.accountCategoriesId === -1 ||
    transactionJurnal.financialAccount === -1;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setTransactionJurnal(prev => ({ ...prev, [id]: value }));
  };

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}/all`);

      setPartners(data);
    } catch (error) {
      console.log('error :', error);
    } finally {
    }
  };

  useEffect(() => {
    handleSearchPartner('');
  }, []);

  useEffect(() => {
    if (journalTransactionsId) {
      setTransactionJurnal(journalTransactionsId);
    }
  }, [journalTransactionsId]);

  const handleSubmit = async () => {
    setIsSubmit(true);
    try {
      const { data } = await axios.post(JOURNAL_TRANSACTIONS_BASE_URL, transactionJurnal);
      reFetchData();
      reMounted();
      handleSnackBar(true, 'success', 'Transaksi jurnal umum berhasil diubah.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Transaksi jurnal umum berhasil gagal diubah.');
    }
  };

  const reMounted = () => {
    setTransactionJurnal(dummyTransactionJurnal);
    setIsSubmit(false);
    handleCloseUpdateOtherIncome();
  };

  const handleCloseCreates = () => {
    reMounted();
    handleCloseUpdateOtherIncome();
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = event.target.value;
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
      setTransactionJurnal(prev => ({
        ...prev,
        date: inputDate
      }));
    } else {
      console.error('Invalid date format:', inputDate);
    }
  };

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>Ubah {type}</Typography>
          <IconButton onClick={handleCloseCreates} size='small'>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row'>
          <Grid xs={12}>
            <Grid container justify='space-between' spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='financialAccount'
                  required
                  label='Akun Bank/Kas'
                  value={transactionJurnal.financialAccount as number}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTransactionJurnal(prev => ({
                      ...prev,
                      financialAccount: Number(event.target.value)
                    }))
                  }
                  select
                  fullWidth
                >
                  <MenuItem value={-1} key={'other'}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title='Pilih akun bank atau kas yang menerima dana dalam perusahaan' arrow>
                        <InfoOutlinedIcon
                          style={{
                            marginRight: 8,
                            fontSize: 16,
                            color: '#888',
                            cursor: 'pointer'
                          }}
                        />
                      </Tooltip>
                      Pilih Akun
                    </span>
                  </MenuItem>
                  {financialAccounts.length > 0 &&
                    financialAccounts.map((value, index) => (
                      <MenuItem key={index} value={value.id}>
                        {value.name} - {value.number}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='date'
                  required
                  fullWidth
                  name='date'
                  label='Tanggal'
                  type='date'
                  value={transactionJurnal.date || new Date().toISOString().split('T')[0]}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              id='accountCategoriesId'
              required
              label='Akun Keuangan'
              value={transactionJurnal.accountCategoriesId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setTransactionJurnal(prev => ({
                  ...prev,
                  accountCategoriesId: Number(event.target.value)
                }))
              }
              select
              fullWidth
            >
              <MenuItem value={-1} key={'accountCategoriesId'}>
                <span style={{ display: 'flex', alignItems: 'center' }}>Pilih Akun</span>
              </MenuItem>
              {subAccountCategories.length > 0 &&
                subAccountCategories.map((value, index) => (
                  <MenuItem key={index} value={value.id}>
                    {value.name} - {value.number}
                  </MenuItem>
                ))}
            </TextField>

            {transactionJurnal.partnerId !== 0 ? (
              <Autocomplete
                id='customer'
                options={partners}
                getOptionLabel={option => option.name || ''}
                value={partners.find(partner => partner.id === transactionJurnal.partnerId) || null}
                onChange={(event, newValue) => {
                  setTransactionJurnal(prev => ({
                    ...prev,
                    partnerId: newValue ? newValue.id : 0 // Default to -1 if no value selected
                  }));
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label='Customer'
                    placeholder='Pilih Customer'
                    fullWidth
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            ) : null}

            {transactionJurnal.debit == 0 ? (
              <TextField
                id='credit'
                required
                fullWidth
                placeholder='Isi nominal transaksi'
                name='credit'
                autoComplete='off'
                label='Jumlah Credit'
                value={transactionJurnal.credit}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (/^\d*$/.test(value)) {
                    setTransactionJurnal(prev => ({
                      ...prev,
                      credit: Number(value),
                      debit: 0
                    }));
                  }
                }}
                InputProps={{
                  inputComponent: NumberFormatMask as any
                }}
              />
            ) : (
              <TextField
                id='debit'
                required
                fullWidth
                placeholder='Isi nominal transaksi'
                name='debit'
                autoComplete='off'
                label='Jumlah Debit'
                value={transactionJurnal.debit}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (/^\d*$/.test(value)) {
                    setTransactionJurnal(prev => ({
                      ...prev,
                      debit: Number(value),
                      credit: 0
                    }));
                  }
                }}
                InputProps={{
                  inputComponent: NumberFormatMask as any
                }}
              />
            )}

            <TextField
              id='description'
              label='Keterangan'
              placeholder='cth. Transfer sms, Setoran,  Pinjaman dll.'
              multiline
              rows={4}
              variant='outlined'
              fullWidth
              value={transactionJurnal.description}
              onChange={handleChange}
            />
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary' onClick={handleCloseCreates}>
              Batal
            </Button>

            <Button onClick={handleSubmit} disabled={isSubmit || disabled}>
              {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(UpdateOtherIncome);
