import React, { FC, Fragment } from 'react';
import { Tooltip, IconButton, TextField, MenuItem, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';
import NumberFormatMask from 'components/NumberFormatMask';
import CheckMarkItem from 'images/icon/CheckMarkItem.svg';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import TypePiece from 'typings/enum/TypePiece';

interface Props {
  purchaseInvoice: PurchaseInvoiceModel;
  isSubmit: boolean;
  note: string;
  type: string;
  price: number;
  priceMessage: string;
  typeMessage: string;
  isLoadingPiece: boolean;
  setPricePiece: React.Dispatch<React.SetStateAction<number>>;
  setTypePiece: React.Dispatch<React.SetStateAction<string>>;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  handleSave: () => void;
  handleClose: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  addProductCell: {
    border: 'none',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'block'
    }
  }
}));

const PieceWizard: FC<Props> = props => {
  const {
    isSubmit,
    note,
    type,
    price,
    priceMessage,
    typeMessage,
    isLoadingPiece,
    setPricePiece,
    setTypePiece,
    setNote,
    handleSave,
    handleClose
  } = props;

  const classes = useStyles();

  console.log();

  return (
    <TableRowCustom>
      <TableCellStart className={classes.addProductCell}>
        <TextField
          id='type'
          required
          label='Metode'
          value={type as string}
          error={typeMessage !== ''}
          helperText={typeMessage}
          onChange={e => setTypePiece(e.target.value as string)}
          select
          fullWidth
        >
          <MenuItem key={new Date().getTime()}>Jenis Potongan</MenuItem>
          {Object.entries(TypePiece).map(([keys, values]) => (
            <MenuItem key={keys} value={keys}>
              {values}
            </MenuItem>
          ))}
        </TextField>
      </TableCellStart>
      <TableCellMiddle className={classes.addProductCell}>
        <TextField
          fullWidth
          id='price'
          required
          label='Jumlah Potongan'
          value={price > 0 ? price : ''}
          error={priceMessage !== ''}
          helperText={priceMessage}
          onChange={e => setPricePiece(+e.target.value)}
          InputProps={{
            inputComponent: NumberFormatMask as any
          }}
        />
      </TableCellMiddle>
      <TableCellMiddle className={classes.addProductCell}>
        <TextField fullWidth id='note' label='Catatan' value={note} onChange={e => setNote(e.target.value)} />
      </TableCellMiddle>
      <TableCellEnd className={classes.addProductCell}>
        {isLoadingPiece ? (
          <CircularProgress size={20} color='inherit' />
        ) : (
          <Fragment>
            <Tooltip title='Simpan'>
              <span>
                <IconButton disabled={isSubmit} size='small' onClick={handleSave}>
                  <img src={CheckMarkItem} alt='simpan' />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='Batal'>
              <span>
                <IconButton disabled={isSubmit} size='small' onClick={handleClose}>
                  <img src={CloseCircleItem} alt='batal' />
                </IconButton>
              </span>
            </Tooltip>
          </Fragment>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default PieceWizard;
