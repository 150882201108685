import { makeStyles, Theme } from '@material-ui/core';
import { Page } from 'components';
import React from 'react';
import BalanceSheetForm from './components/CreatePage';
import { GREEN, WHITE } from 'constants/colors';

export type TypeIncomeStatement = {
  isLoading: boolean;
  data: IncomeStatement[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const BalanceSheetPage = () => {
  return (
    <Page title='Neraca'>
      <BalanceSheetForm />
    </Page>
  );
};

export default BalanceSheetPage;
