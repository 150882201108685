import React, { memo, useEffect, useState } from 'react';
import { DialogContent, Dialog, Grid, Typography, IconButton, TextField, makeStyles, Button, CircularProgress } from '@material-ui/core';
import { JOURNAL_TRANSACTIONS_BASE_URL } from 'constants/url';
import { COST_BALANCE_BASE_URL } from 'constants/url';
import { Close } from '@material-ui/icons';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummycostBalance, dummyTransactionJurnal } from 'utils/dummy';
import axios from 'axios';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Autocomplete } from '@material-ui/lab';
import { CashBalance } from 'typings/CashBalanceModel';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',

    maxWidth: 500,
    justifyContent: 'center'
  },
  tab: {
    minWidth: 'auto',
    padding: '10px',
    margin: '0 4px'
  }
});

interface Props {
  financialAccounts: AccountCategoryModel[];
  subAccountCategories: AccountCategoryModel[];
  journalTransactionsId: CashBalance | null;
  open: boolean;
  handleCloseCreate: () => void;
  reFetchData: () => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const CreateTransferInternal = ({
  handleSnackBar,
  financialAccounts,
  journalTransactionsId,
  subAccountCategories,
  open,
  handleCloseCreate,
  reFetchData
}: Props) => {
  const classes = useStyles();
  const [costBalance, setCostBalance] = useState<CashBalance>(dummycostBalance);
  const [transactionJurnal, setTransactionJurnal] = useState<transactionJurnal>(dummyTransactionJurnal);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const disabled = Number(costBalance.total) == 0 || costBalance.financialAccount == 0;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setTransactionJurnal(prev => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (journalTransactionsId) {
      setCostBalance(journalTransactionsId);
    }
  }, [journalTransactionsId]);

  const handleSubmit = async () => {
    setIsSubmit(true);
    try {
      //const { data } = await axios.post(JOURNAL_TRANSACTIONS_BASE_URL, dataFinal);
      const { data } = await axios.post(COST_BALANCE_BASE_URL, costBalance);

      reFetchData();
      reMounted();
      handleSnackBar(true, 'success', 'Saldo Kas berhasil ditambahkan.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Saldo Kas gagal ditambahkan.');
    }
  };

  const reMounted = () => {
    setCostBalance(dummycostBalance);
    setTransactionJurnal(dummyTransactionJurnal);
    setIsSubmit(false);
    handleCloseCreate();
  };

  const handleCloseCreates = () => {
    reMounted();
    handleCloseCreate();
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = event.target.value;
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
      setTransactionJurnal(prev => ({
        ...prev,
        date: inputDate
      }));
      setCostBalance(prev => ({
        ...prev,
        date: inputDate
      }));
    } else {
      console.error('Invalid date format:', inputDate);
    }
  };

  return (
    <Dialog maxWidth={'md'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>Tambah Saldo Kas</Typography>
          <IconButton onClick={handleCloseCreates} size='small'>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row'>
          <Grid xs={12}>
            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
              <Autocomplete
                id='financialAccount'
                options={financialAccounts}
                getOptionLabel={option => `${option.name} - ${option.number}`}
                // isOptionEqualToValue={(option, value) => option.id === value?.id}
                value={financialAccounts.find(account => account.id === costBalance.financialAccount) || null}
                onChange={(event, value) =>
                  setCostBalance(prev => ({
                    ...prev,
                    financialAccount: value?.id || 0
                  }))
                }
                noOptionsText='Tidak ada opsi'
                renderInput={params => <TextField {...params} label={<span style={{ display: 'flex', alignItems: 'center' }}>Kas</span>} fullWidth />}
                fullWidth
              />
            </Grid>

            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
              <TextField
                id='date'
                required
                fullWidth
                name='date'
                label='Tanggal'
                type='date'
                value={costBalance.date || new Date().toISOString().split('T')[0]}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <TextField
              id='jumlah'
              required
              fullWidth
              placeholder='Isi nominal'
              name='jumlah'
              autoComplete='off'
              value={costBalance.total ? costBalance.total : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (/^\d*$/.test(value)) {
                  setCostBalance(prev => ({
                    ...prev,
                    total: Number(value)
                  }));
                }
              }}
              label='Jumlah'
              InputProps={{
                inputComponent: NumberFormatMask as any
              }}
            />
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary' onClick={handleCloseCreates}>
              Batal
            </Button>

            <Button onClick={handleSubmit} disabled={isSubmit || disabled}>
              {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CreateTransferInternal);
