import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, Button, TableContainer } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyPurchaseInvoice, dummyPurchaseInvoicePiece } from 'utils/dummy';
import { PaperCustom } from 'components';
import PieceWizard from './components/PieceWizad';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  invoicePiece: PurchaseInvoicePieceModel[];
  purchaseInvoice: PurchaseInvoiceModel;
  isSubmit: boolean;
  price: number;
  priceMessage: string;
  note: string;
  type: string;
  openCreate: boolean;
  isLoadingPiece: boolean;
  setPricePiece: React.Dispatch<React.SetStateAction<number>>;
  setTypePiece: React.Dispatch<React.SetStateAction<string>>;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  handleSave: () => void;
  handleClose: () => void;
  handleOpenCreatePiece: () => void;
  handleDelete: (pieceId: number) => React.MouseEventHandler;
  totalPrice: number;
}

const PieceTable: FC<Props> = props => {
  const {
    isLoadingData,
    purchaseInvoice,
    invoicePiece,
    isSubmit,
    note,
    price,
    priceMessage,
    type,
    isLoadingPiece,
    openCreate,
    setPricePiece,
    setTypePiece,
    setNote,
    handleSave,
    handleClose,
    handleOpenCreatePiece,
    handleDelete,
    totalPrice
  } = props;

  return (
    <PaperCustom>
      <TableContainer>
        <TableCustom size='small'>
          <TableHead>
            <HeaderRow headers={[{ label: 'Jenis Potongan' }, { label: 'Total Potongan' }, { label: 'Catatan' }, { label: '' }]} />
          </TableHead>
          <TableBody>
            {isLoadingData
              ? [0, 1, 2, 3].map(index => (
                  <BodyRow
                    key={index}
                    index={index}
                    purchaseInvoice={dummyPurchaseInvoice}
                    invoicePiece={dummyPurchaseInvoicePiece}
                    isLoading={isLoadingData}
                    isLoadingPiece={isLoadingPiece}
                    handleDelete={handleDelete}
                    totalPrice={totalPrice}
                  />
                ))
              : invoicePiece && invoicePiece.length > 0
              ? invoicePiece.map((value, index) => (
                  <BodyRow
                    key={index}
                    index={index}
                    purchaseInvoice={purchaseInvoice}
                    invoicePiece={value}
                    isLoading={isLoadingData}
                    isLoadingPiece={isLoadingPiece}
                    handleDelete={handleDelete}
                    totalPrice={totalPrice}
                  />
                ))
              : !openCreate && (
                  <TableRow>
                    <TableCell colSpan={4} align='center'>
                      Belum Ada Potongan
                    </TableCell>
                  </TableRow>
                )}

            {!openCreate && purchaseInvoice.statusPayment == 'UNPAID' && (
              <TableRow>
                <TableCell colSpan={4} align='right' style={{ borderBottom: 'none' }}>
                  <Button disabled={isSubmit || isLoadingPiece} onClick={handleOpenCreatePiece}>
                    Tambah Potongan
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>

      <TableContainer>
        <TableCustom size='small'>
          <TableBody>
            {openCreate && (
              <PieceWizard
                purchaseInvoice={purchaseInvoice}
                isSubmit={isSubmit}
                note={note}
                type={type}
                price={price}
                priceMessage={priceMessage}
                typeMessage={''}
                isLoadingPiece={isLoadingPiece}
                setPricePiece={setPricePiece}
                setTypePiece={setTypePiece}
                setNote={setNote}
                handleSave={handleSave}
                handleClose={handleClose}
              />
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
    </PaperCustom>
  );
};

export default PieceTable;
