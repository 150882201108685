import React, { FC } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  isUpdate: boolean;
  checked: InvoicePaymentModel[];
  checkedInvoice: InvoiceModel[];
  totalPay: number;
  handleIndividualCheck: (item: InvoicePaymentModel) => void;
}

const InvoiceTable: FC<Props> = props => {
  const { isLoadingData, isUpdate, checked, checkedInvoice, totalPay, handleIndividualCheck } = props;
  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='center'></TableCell>
            <TableCell align='center'>Nomor Invoice</TableCell>
            <TableCell align='center'>Tgl Pelunasan</TableCell>
            <TableCell align='center'>Total Jumlah</TableCell>
            <TableCell align='right'>Jumlah Tagihan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checkedInvoice.length > 0 &&
            checkedInvoice.map((value, index) => (
              <BodyRow
                key={index}
                index={index}
                item={checked[index]}
                invoice={value}
                isLoading={isLoadingData}
                checked={checked}
                isUpdate={isUpdate}
                totalPay={totalPay}
                handleIndividualCheck={handleIndividualCheck}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
