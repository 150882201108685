import React, { FC, Fragment } from 'react';
import { TextField, TableRow, TableCell, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import DoneIcon from '@material-ui/icons/Done';

interface Props {
  bank: BankModel;
  bankProgress: boolean;
  currentIndex: number;
  index: number;
  setBank: React.Dispatch<React.SetStateAction<BankModel>>;
  handleSave: () => void;
  handleCancel: () => void;
}

const BankWizard: FC<Props> = props => {
  const { bank, bankProgress, setBank, handleSave, handleCancel } = props;
  const { currentIndex, index } = props;

  return (
    <Fragment>
      <TableRow>
        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
          <TextField
            id='bankName'
            name='bankName'
            required
            fullWidth
            label='Nama Bank'
            value={bank.bankName}
            onChange={event => setBank({ ...bank, bankName: event.target.value })}
          />
        </TableCell>

        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
          <TextField
            id='bankCode'
            name='bankCode'
            required
            fullWidth
            label='Kode Bank '
            value={bank.bankCode}
            onChange={event => setBank({ ...bank, bankCode: event.target.value })}
          />
        </TableCell>

        <TableCell rowSpan={3} align='center'>
          {bankProgress && index === currentIndex ? (
            <CircularProgress color='inherit' size={20} />
          ) : (
            <Fragment>
              <Tooltip title='Simpan'>
                <IconButton size='small' onClick={handleSave}>
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Batal'>
                <IconButton size='small' onClick={handleCancel}>
                  <img src={CloseCircleItem} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
          <TextField
            id='accountName'
            required
            fullWidth
            label='Nama Akun'
            value={bank.bankAccount}
            onChange={event => setBank({ ...bank, bankAccount: event.target.value })}
          />
        </TableCell>

        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
          <TextField
            id='bankNumber'
            required
            fullWidth
            label='Bank Number'
            value={bank.bankNumber}
            onChange={event => setBank({ ...bank, bankNumber: event.target.value })}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          <TextField
            id='accountName'
            required
            fullWidth
            label='Kode Bagan Akun Standart'
            value={bank.accountCode}
            onChange={event => setBank({ ...bank, accountCode: +event.target.value })}
          />
        </TableCell>

        <TableCell colSpan={2}></TableCell>
      </TableRow>
    </Fragment>
  );
};

export default BankWizard;
