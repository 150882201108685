import React, { FC, Fragment } from 'react';
import { IconButton, CircularProgress } from '@material-ui/core';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import TypePiece from 'typings/enum/TypePiece';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props {
  invoicePiece: PurchaseInvoicePieceModel;
  purchaseInvoice: PurchaseInvoiceModel;
  isLoading: boolean;
  isLoadingPiece: boolean;
  index: number;
  handleDelete: (pieceId: number) => React.MouseEventHandler;
  totalPrice: number;
}

const BodyRow: FC<Props> = props => {
  const { purchaseInvoice, invoicePiece, isLoading, handleDelete, totalPrice } = props;

  return (
    <TableRowCustom>
      <TableCellStart>{isLoading ? <Skeleton variant='text' width={120} height={25} /> : TypePiece[invoicePiece.type]}</TableCellStart>

      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={120} height={25} />
        ) : (
          <NumberFormat value={invoicePiece.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={120} height={25} /> : invoicePiece.note || '-'}</TableCellMiddle>

      {purchaseInvoice.statusPayment == 'UNPAID' && totalPrice - purchaseInvoice.totalPay !== 0 && (
        <TableCellEnd align='center' colSpan={2}>
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : isLoading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            <Fragment>
              <IconButton size='small' onClick={handleDelete(invoicePiece.id)}>
                <DeleteIcon />
              </IconButton>
            </Fragment>
          )}
        </TableCellEnd>
      )}
    </TableRowCustom>
  );
};

export default BodyRow;
