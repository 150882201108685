import React, { Fragment, memo, useEffect, useState } from 'react';
import { Grid, TextField, makeStyles, Button, CircularProgress, MenuItem, Divider, Typography, Tooltip, InputAdornment } from '@material-ui/core';
import { JOURNAL_TRANSACTIONS_BASE_URL, PARTNER_BASE_URL } from 'constants/url';
import { CardCustomer, NumberFormatMask } from 'components';

import { PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyTransactionJurnal, dummyPartner } from 'utils/dummy';
import axios from 'axios';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',

    maxWidth: 500,
    justifyContent: 'center'
  },
  tab: {
    minWidth: 'auto',
    padding: '10px',
    margin: '0 4px'
  }
});
interface Props {
  financialAccounts: AccountCategoryModel[];
  subAccountCategories: AccountCategoryModel[];
  handleCloseCreate: () => void;
  reFetchData: () => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const CreatePurchaseDeposit = ({ handleSnackBar, financialAccounts, subAccountCategories, handleCloseCreate, reFetchData }: Props) => {
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);
  const classes = useStyles();
  const [transactionJurnal, setTransactionJurnal] = useState<transactionJurnal>(dummyTransactionJurnal);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const disabled =
    Number(transactionJurnal.credit) == 0 ||
    transactionJurnal.accountCategoriesId == -1 ||
    transactionJurnal.financialAccount == -1 ||
    transactionJurnal.partnerId == 0;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setTransactionJurnal(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    try {
      const dataFinal = {
        ...transactionJurnal,
        type: 'Panjar Pembelian'
      };

      const { data } = await axios.post(JOURNAL_TRANSACTIONS_BASE_URL, dataFinal);
      reFetchData();
      reMounted();
      handleSnackBar(true, 'success', 'Transaksi jurnal umum berhasil ditambahkan.');
    } catch (error) {
      console.log(error);
      handleSnackBar(true, 'error', 'Transaksi jurnal umum berhasil gagal ditambahkan.');
    }
  };

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('partnerType', 'SUPPLIER');

    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}/all?${params.toString()}`);

      setPartners(data);
    } catch (error) {
      console.log('error :', error);
    } finally {
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = event.target.value;
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
      setTransactionJurnal(prev => ({
        ...prev,
        date: inputDate
      }));
    } else {
      console.error('Invalid date format:', inputDate);
    }
  };

  useEffect(() => {
    handleSearchPartner('');
  }, []);
  const reMounted = () => {
    setTransactionJurnal(dummyTransactionJurnal);
    setIsSubmit(false);
    handleCloseCreate();
  };

  const handleCloseCreates = () => {
    reMounted();
    handleCloseCreate();
  };

  return (
    <Grid container direction='row'>
      <Grid xs={12}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h6'>{'Tambah Panjar Pembelian'}</Typography>
            <Divider variant='fullWidth' />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Autocomplete
              id='financialAccountId'
              options={financialAccounts}
              getOptionLabel={option => `${option.name} - ${option.number}` || ''}
              value={financialAccounts.find(account => account.id === transactionJurnal.financialAccount) || null}
              onChange={(event, newValue) => {
                setTransactionJurnal(prev => ({
                  ...prev,
                  financialAccount: newValue ? newValue.id : 0
                }));
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label='Akun Bank/Kas'
                  placeholder='Pilih Akun Bank/Kas'
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Tooltip title='Pilih akun bank atau kas yang menerima dana dalam perusahaan' arrow>
                        <InfoOutlinedIcon
                          style={{
                            marginRight: 8,
                            fontSize: 16,
                            color: '#888',
                            cursor: 'pointer'
                          }}
                        />
                      </Tooltip>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              id='date'
              required
              fullWidth
              name='date'
              label='Tanggal'
              type='date'
              value={transactionJurnal.date || new Date().toISOString().split('T')[0]}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>

        <Autocomplete
          id='financialAccount'
          options={subAccountCategories}
          getOptionLabel={option => `${option.name} - ${option.number}` || ''}
          value={subAccountCategories.find(category => category.id === transactionJurnal.accountCategoriesId) || null}
          onChange={(event, newValue) => {
            setTransactionJurnal(prev => ({
              ...prev,
              accountCategoriesId: newValue ? newValue.id : 0 // Default to 0 if no value selected
            }));
          }}
          renderInput={params => (
            <TextField
              {...params}
              required
              label='Akun Debit'
              placeholder='Pilih Akun'
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Tooltip title='Pilih akun mitra' arrow>
                    <InfoOutlinedIcon
                      style={{
                        marginRight: 8,
                        fontSize: 16,
                        color: '#888',
                        cursor: 'pointer'
                      }}
                    />
                  </Tooltip>
                )
              }}
            />
          )}
        />
        <Autocomplete
          id='supplier'
          options={partners}
          getOptionLabel={option => option.name || ''}
          value={partners.find(partner => partner.id === transactionJurnal.partnerId) || null}
          onChange={(event, newValue) => {
            setTransactionJurnal(prev => ({
              ...prev,
              partnerId: newValue ? newValue.id : 0 // Default to -1 if no value selected
            }));
          }}
          renderInput={params => (
            <TextField
              {...params}
              required
              label='Supplier'
              placeholder='Pilih Supplier'
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Tooltip title='Pilih Supplier' arrow>
                    <InfoOutlinedIcon
                      style={{
                        marginRight: 8,
                        fontSize: 16,
                        color: '#888',
                        cursor: 'pointer'
                      }}
                    />
                  </Tooltip>
                )
              }}
            />
          )}
        />

        <TextField
          id='credit'
          required
          fullWidth
          placeholder='Isi nominal transaksi'
          name='credit'
          autoComplete='off'
          label='Jumlah'
          value={transactionJurnal.credit}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (/^\d*$/.test(value)) {
              setTransactionJurnal(prev => ({
                ...prev,
                credit: Number(value)
              }));
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Tooltip title='Masukkan nominal transaksi' arrow>
                  <InfoOutlinedIcon style={{ cursor: 'pointer', fontSize: 18, color: '#888' }} />
                </Tooltip>
              </InputAdornment>
            ),
            inputComponent: NumberFormatMask as any
          }}
        />

        <TextField
          id='description'
          label='Keterangan'
          placeholder='cth. Transfer sms, Setoran,  Pinjaman dll.'
          multiline
          rows={4}
          variant='outlined'
          fullWidth
          value={transactionJurnal.description}
          onChange={handleChange}
        />
      </Grid>

      <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
        <Button color='secondary' onClick={handleCloseCreates}>
          Batal
        </Button>

        <Button onClick={handleSubmit} disabled={isSubmit || disabled}>
          {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(CreatePurchaseDeposit);
