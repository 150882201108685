import { Box, CircularProgress, Grid, InputAdornment, ListItem, makeStyles, TextField, Typography } from '@material-ui/core';
import { CloseRounded, SearchRounded, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import FlexBox from 'components/FlexBox';
import { GRAY_3, GREY, WHITE } from 'constants/colors';
import React, { useState } from 'react';
import { dummyFilters, TFilters } from 'pages/PartnerDetailPage/components/SalesHistoryPage';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

const useStyles = makeStyles({
  productWrapper: {
    position: 'relative'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  },
  absolute: {
    position: 'absolute',
    top: 1200,
    width: '38vw',
    zIndex: 3
  },
  scrollY: {
    maxHeight: '50vh',
    overflowY: 'scroll'
  },
  searchIcon: {
    padding: '.1em',
    fontSize: '1.7rem',
    color: '#323232',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: GREY,
      borderRadius: '50%'
    }
  },
  colorGrey: {
    color: GRAY_3
  },
  wrapperInput: {
    backgroundColor: WHITE
  }
});
/* Defining the props that the component will receive. */
interface Props {
  filters: TFilters;
  invoiceId: string;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
}

/* A function that returns a component. */
const PriceHistoryFilters = ({ filters, invoiceId, setInvoiceId }: Props) => {
  const classes = useStyles();
  /* A state that is used to show or hide the suggest list. */

  return (
    <Grid container direction='row' spacing={1} justify='flex-start'>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <TextField
          id='invoiceId'
          fullWidth
          placeholder='Cari Nomor Kwitansi'
          value={invoiceId}
          onChange={event => setInvoiceId(event.target.value)}
        />
      </Grid>
    </Grid>
  );
};

/* Exporting the component. */
export default PriceHistoryFilters;
